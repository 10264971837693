<template>
  <client-only>
    <printer-events-tracker />
    <fr-button
      v-if="printer"
      :variant="variant"
      :loading="printer.loading.value"
      :disabled="printer.loading.value"
      icon="fal fa-print"
      @click="
        () => {
          if (printer) printer.models.value.default_printer = true
        }
      "
      class="border border-fr-gray-250 !bg-fr-gray-200 !text-fr-gray-900 !shadow-none dark:border-none dark:!bg-fr-gray-850 dark:!text-fr-gray-250"
    >
      <q-badge
        v-if="printer.printerConfigs.value.some((config) => config.auto_print)"
        color="black"
        floating
      >
        <q-icon name="fal fa-a" size="10px" />
      </q-badge>
      <q-tooltip anchor="top middle" self="bottom middle">
        <p class="text-center">Auto-print enabled printers</p>

        <q-separator />
        <div
          v-for="autoPrinters in printer.printerConfigs.value.filter(
            (config) => config.auto_print
          )"
          :key="autoPrinters.printer_name"
        >
          <ul>
            <li>
              {{ autoPrinters.printer_name }}
            </li>
          </ul>
        </div>
      </q-tooltip>
    </fr-button>
    <q-dialog
      v-if="printer"
      v-model="printer.models.value.default_printer"
      :maximized="
        !!(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet')
      "
      position="right"
      persistent
      class="shadow-none"
    >
      <printers-settings-modal :tab="props.tab" />
    </q-dialog>
  </client-only>
</template>

<script setup lang="ts">
import printersSettingsModal from './modals/printers-settings-modal.vue'
import { PrinterConfigFileNames } from '../store/types'
import type { CampfirePrinterType } from '../store/printer-class.client'
import PrinterEventsTracker from './printer-events-tracker.vue'

const { $CampfirePrinter } = useNuxtApp()
let printer: CampfirePrinterType

const props = withDefaults(
  defineProps<{
    tab?: PrinterConfigFileNames
    variant?:
      | 'outline'
      | 'secondary'
      | 'danger'
      | 'warning'
      | 'success'
      | 'link'
      | 'ghost'
      | string
      | null
  }>(),
  {
    tab: PrinterConfigFileNames.deliveryNotes,
    variant: 'ghost'
  }
)

onMounted(async () => {
  // @ts-expect-error - $CampfirePrinter is not defined
  printer = new $CampfirePrinter()

  await printer?.check()
})

onBeforeUnmount(() => {
  if (printer) printer.models.value.default_printer = false
})
</script>
